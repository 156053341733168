<div class="flex justify-content-center align-items-center mt-8">
    <div class="surface-card p-4 shadow-5 border-round w-full lg:w-4">

        <div class="text-center mb-5">
            <p-image src="assets/images/logo.svg" alt="logo" class="mt-3 mb-3" imageClass="my-4"></p-image>
            <div class="text-900 text-3xl font-medium mt-3 mb-3">Портал аналитики</div>
        </div>

        <div>
            <label for="login" class="block text-900 font-medium mb-2">Логин</label>
            <input (keyup.enter)="onClickLogin()" id="login" type="text" placeholder="" [(ngModel)]="login" pInputText class="w-full mb-3">

            <label for="password" class="block text-900 font-medium mb-2">Пароль</label>
            <p-password (keyup.enter)="onClickLogin()" id="password" placeholder="" [(ngModel)]="password" [feedback]="false" [toggleMask]="true"
                styleClass="w-full mb-3" inputStyleClass="w-full" />

            <p-button pRipple (onClick)="onClickLogin()" label="Авторизоваться" icon="pi pi-key" styleClass="w-full"
                [loading]="loadingLogin" [disabled]="loadingLogin"></p-button>
        </div>

    </div>
</div>