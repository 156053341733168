<p-tieredMenu #menu [model]="profileMenu" [popup]="true" />

<p-menubar [model]="navbarMenu">
    <ng-template pTemplate="start">
        <a [routerLink]="['/reports']">
            <p-image src="/assets/images/logo.svg" styleClass="mx-1" imageClass="my-1 h-3rem"></p-image>
        </a>
    </ng-template>
    <ng-template pTemplate="end">
        <div (click)="menu.toggle($event)" class="flex align-items-center gap-2 cursor-pointer">
            {{userContext?.fullName}}
            <p-avatar label="U" shape="circle" styleClass="mx-2" />
        </div>
    </ng-template>
</p-menubar>