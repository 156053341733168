import { NgModule } from '@angular/core';

import { environment } from 'src/environments/environment';

import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_shared/guards/auth.guard';

import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './report/report.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { NonAuthGuard } from './_shared/guards/non-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/reports',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
    title: 'Авторизация'
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: 'Выход'
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    title: 'Список отчетов'
  },
  {
    path: 'report/:id',
    component: ReportComponent,
    canActivate: [AuthGuard],
    title: 'Отчет'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: '404 - Страница не найдена'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
