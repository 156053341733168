import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { EventHandler } from 'powerbi-client-angular/components/powerbi-embed/powerbi-embed.component';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

    @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

    loadingReport: boolean = false;

    reportGuid: string = "";

    embedConfig: IReportEmbedConfiguration = {
        type: "report",
        id: undefined,
        embedUrl: undefined,
        accessToken: undefined,
        tokenType: models.TokenType.Embed,
        hostname: "https://app.powerbi.com"
    };

    eventHandlers: Map<string, EventHandler | null> = new Map([
        ['loaded', () => {
            console.log('Report loaded');
            this.loadingReport = false;
        }],
        ['rendered', () => {
            console.log('Report rendered');
        }],
        ['error', (event: any) => {
            console.log("report error:", event);
        }]
    ]);

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private route: ActivatedRoute,
    ) {

    }


    ngOnInit() {
        this.reportGuid = this.route.snapshot.paramMap.get('id') ?? "";
        this.loadReport();
    }

    loadReport() {
        this.loadingReport = true;
        this.http.get(environment.apiConfig.apiUrl + '/report/' + this.reportGuid).toPromise()
            .then((response: any) => {
                console.log("response ", response);
                //console.log("this.reportObj.embedConfig.accessToken", this.reportObj.embedConfig.accessToken);
                this.embedConfig = {
                    ...this.embedConfig,
                    accessToken: response.embeddedToken,
                    embedUrl: response.embeddedUrl,
                };
            }).catch((error: any) => {
                console.log("error", error);
                this.messageService.add({
                    severity: 'error',
                    summary: error?.error?.title ?? "Ошибка",
                    detail: error?.error?.detail ?? "Неизвестная ошибка",
                    life: 5000,
                });
            })
            .finally(() => {
                this.loadingReport = false;
            });
    }

}
