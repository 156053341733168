import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private primengConfig: PrimeNGConfig,
  ) {

  }

  ngOnInit() {
    // Init PrimeNG Ripple Effect
    this.primengConfig.ripple = true;

    //Translate
    this.primengConfig.setTranslation({
      "accept": "Да",
      "addRule": "Добавить правило",
      "am": "до полудня",
      "apply": "Принять",
      "cancel": "Отмена",
      "choose": "Выбрать",
      "chooseDate": "Выбрать дату",
      "chooseMonth": "Выбрать месяц",
      "chooseYear": "Выбрать год",
      "clear": "Очистить",
      //"completed": "Завершено",  // оставляем закомментированным
      "contains": "Содержит",
      //"custom": "Пользовательский",  // оставляем закомментированным
      "dateAfter": "Дата после",
      "dateBefore": "Дата до",
      "dateFormat": "dd.mm.yy",
      "dateIs": "Дата равна",
      "dateIsNot": "Дата не равна",
      "dayNames": [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота"
      ],
      "dayNamesMin": [
        "Вс",
        "Пн",
        "Вт",
        "Ср",
        "Чт",
        "Пт",
        "Сб"
      ],
      "dayNamesShort": [
        "Вск",
        "Пнд",
        "Втр",
        "Срд",
        "Чтв",
        "Птн",
        "Сбт"
      ],
      "emptyFilterMessage": "Результатов не найдено",
      "emptyMessage": "Нет доступных вариантов",
      "emptySearchMessage": "Результатов не найдено",
      "emptySelectionMessage": "Нет выбранного элемента",
      "endsWith": "Заканчивается",
      "equals": "Равно",
      "fileSizeTypes": [
        "Б",
        "Кб",
        "Мб",
        "Гб",
        "Тб",
        "Пб",
        "Эб",
        "Зб",
        "Йб"
      ],
      //"filter": "Фильтр",  // оставляем закомментированным
      "firstDayOfWeek": 1,
      "gt": "Более чем",
      "gte": "Более чем или равно",
      "lt": "Меньше чем",
      "lte": "Меньше чем или равно",
      "matchAll": "Сопоставить все",
      "matchAny": "Совпадение с любым",
      "medium": "Нормальный",
      "monthNames": [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      "monthNamesShort": [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июн",
        "Июл",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек"
      ],
      "nextDecade": "Следующее десятилетие",
      "nextHour": "Следующий час",
      "nextMinute": "Следующая минута",
      "nextMonth": "Следующий месяц",
      "nextSecond": "Следующая секунда",
      "nextYear": "Следующий год",
      "noFilter": "Нет фильтра",
      "notContains": "Не содержит",
      "notEquals": "Не равно",
      //"now": "Сейчас",  // оставляем закомментированным
      "passwordPrompt": "Введите пароль",
      "pending": "В ожидании",
      "pm": "после полудня",
      "prevDecade": "Предыдущее десятилетие",
      "prevHour": "Предыдущий час",
      "prevMinute": "Предыдущая минута",
      "prevMonth": "Предыдущий месяц",
      "prevSecond": "Предыдущая секунда",
      "prevYear": "Предыдущий год",
      "reject": "Нет",
      "removeRule": "Удалить правило",
      "searchMessage": "{0} результатов доступно",
      "selectionMessage": "{0} элементов выбрано",
      //"showMonthAfterYear": false,  // оставляем закомментированным
      "startsWith": "Начинается с",
      "strong": "Хороший",
      "today": "Сегодня",
      "upload": "Загрузить",
      "weak": "Простой",
      "weekHeader": "Нед.",
      "aria": {
        "cancelEdit": "Отменить правку",
        "close": "Закрыть",
        //"collapseLabel": "Свернуть", // оставляем закомментированным
        "collapseRow": "Свернуть строку",
        "editRow": "Редактировать строку",
        //"expandLabel": "Развернуть", // оставляем закомментированным
        "expandRow": "Развернуть строку",
        "falseLabel": "Неверно",
        "filterConstraint": "Ограничение фильтра",
        "filterOperator": "Оператор фильтра",
        "firstPageLabel": "Первая страница",
        "gridView": "В виде сетки",
        "hideFilterMenu": "Скрыть меню фильтра",
        "jumpToPageDropdownLabel": "Перейти к раскрывающемуся списку страниц",
        "jumpToPageInputLabel": "Перейти к вводу страницы",
        "lastPageLabel": "Последняя страница",
        "listView": "В виде списка",
        "moveAllToSource": "Переместить всё в источник",
        "moveAllToTarget": "Переместить всё в приёмник",
        "moveBottom": "Переместить в конец",
        "moveDown": "Переместить вниз",
        "moveTop": "Переместить в начало",
        "moveToSource": "Переместить в источник",
        "moveToTarget": "Переместить в приёмник",
        "moveUp": "Переместить вверх",
        "navigation": "Навигация",
        "next": "Следующий",
        "nextPageLabel": "Следующая страница",
        "nullLabel": "Не выбран",
        //"otpLabel": "Введите символ одноразового пароля {0}",  // оставляем закомментированным
        "pageLabel": "{page}",
        //"passwordHide": "Скрыть пароль",  // оставляем закомментированным
        //"passwordShow": "Показать пароль",  // оставляем закомментированным
        "previous": "Предыдущий",
        "previousPageLabel": "Предыдущая страница",
        "removeLabel": "Удалить",
        "rotateLeft": "Повернуть влево",
        "rotateRight": "Повернуть вправо",
        "rowsPerPageLabel": "Строк на странице",
        "saveEdit": "Сохранить правку",
        "scrollTop": "Прокрутить в начало",
        "selectAll": "Выбрать все строки",
        //"selectLabel": "Выбрать",  // оставляем закомментированным
        "selectRow": "Выбрать строку",
        "showFilterMenu": "Показать меню фильтра",
        "slide": "Слайд",
        "slideNumber": "{slideNumber}",
        "star": "1 звезда",
        "stars": "{star} звёзд",
        "trueLabel": "Верно",
        "unselectAll": "Отменить выбор всех строк",
        //"unselectLabel": "Отменить выбор",  // оставляем закомментированным
        "unselectRow": "Отменить выбор строки",
        "zoomImage": "Увеличить изображение",
        "zoomIn": "Увеличить",
        "zoomOut": "Уменьшить"
      }
    });
    
  }
  
}
