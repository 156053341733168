import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_shared/services/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loadingLogin: boolean = false;

    login: string = "";
    password: string = "";

    constructor(
        private authService: AuthService,
        private router: Router,
        private messageService: MessageService,
    ) {

    }

    ngOnInit() {

    }

    onClickLogin() {

        if (!this.login) {
            this.messageService.add({
                severity: "error",
                summary: "Ошибка",
                detail: "Необходимо указать логин."
            });
            return;
        }

        if (!this.password) {
            this.messageService.add({
                severity: "error",
                summary: "Ошибка",
                detail: "Необходимо указать пароль."
            });
            return;
        }

        this.loadingLogin = true;
        this.authService
            .login(this.login, this.password)
            .then((response) => {
                console.log("response: ", response);
                this.router.navigate(['/reports']);
                this.messageService.clear();
            })
            .catch((error: any) => {
                console.log("error", error);
                this.messageService.add({
                    severity: 'error',
                    summary: error?.error?.title ?? "Ошибка",
                    detail: error?.error?.detail ?? "Неизвестная ошибка",
                    life: 60000,
                });
            })
            .finally(() => {
                this.loadingLogin = false;
            });
    }

}



