import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserContextService {

    isAuthenticatedSubscription: Subscription = new Subscription();

    userContext$: any = new BehaviorSubject({});

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private authService: AuthService,
    ) {
        this.isAuthenticatedSubscription = this.authService.isAuthenticated.subscribe((result: any) => {
            if (result === true) {
                this.loadUserContext();
                return;
            }
            this.userContext$.next({});
        });
    }

    loadUserContext() {
        this.http.get(environment.apiConfig.apiUrl + "/profile").toPromise()
            .then((result: any) => {
                console.log("result", result);
                this.userContext$.next(result);
            })
            .catch((error: any) => {
                console.log("error", error);
                this.messageService.add({
                    severity: 'error',
                    summary: error?.error?.title ?? "Ошибка",
                    detail: error?.error?.detail ?? "Неизвестная ошибка",
                    life: 5000,
                });
            })
            .finally(() => {

            });
    }






}