import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    isRefreshingToken = false;

    constructor(
        private authService: AuthService,
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (this.isInBlockedList(request.url)) {
            return next.handle(request);
        }
        return next.handle(this.addToken(request)).pipe(
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 401:
                            return this.handle401Error(request, next);
                        default:
                            return throwError(error);
                    }
                }
                return throwError(error);
            })
        );
    }

    private isInBlockedList(url: string): Boolean {
        switch (url) {
            case environment.apiConfig.apiUrl + '/token':
            case environment.apiConfig.apiUrl + '/token/refresh':
                return true;
            default:
                return false;
        }
    }

    private addToken(req: HttpRequest<any>) {
        if (!this.authService.currentAccessToken) {
            return req;
        }
        return req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + this.authService.currentAccessToken)
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshingToken) {
            this.tokenSubject.next(null);
            this.isRefreshingToken = true;
            this.authService.currentAccessToken = '';
            return this.authService.postTokenRefresh().pipe(
                switchMap((result: any) => {
                    const accessToken = result.accessToken;
                    const refreshToken = result.refreshToken;
                    return this.authService.storeAccessToken(accessToken, refreshToken).pipe(
                        switchMap(_ => {
                            this.tokenSubject.next(accessToken);
                            return next.handle(this.addToken(request));
                        })
                    );
                }),
                catchError((error: any) => {
                    this.authService.logout();
                    return throwError(error);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        }
        return this.tokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap(token => {
                return next.handle(this.addToken(request));
            })
        );
    }

}