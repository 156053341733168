<div class="grid grid-nogutter">
    <div class="col-12">
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="flex flex-column lg:flex-row justify-content-center align-items-center gap-7">
                <div class="text-center lg:text-right">
                    <div class="mt-6 mb-3 font-bold text-6xl text-900">Вы потерялись?</div>
                    <p class="text-700 text-3xl mt-0 mb-6">Мы сожалеем, что вы не нашли тут страницу.</p>
                    <p-button routerLink="/" label="Вернуться
                            на главную страницу" class="p-button-outlined"></p-button>
                </div>
                <div>
                    <img src="assets/images/404.png" class="w-full md:w-28rem">
                </div>
            </div>
        </div>
    </div>
</div>