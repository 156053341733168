
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {

    constructor(
        private readonly title: Title
    ) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.buildTitle(routerState);
        this.title.setTitle(environment.titlePagePrefix + title + environment.titlePagePostfix);
    }

}
