import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, from, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    currentAccessToken: string = '';
    currentRefreshToken: string = '';

    constructor(
        private http: HttpClient,
        private router: Router,
        private messageService: MessageService,
    ) {
        this.init();
    }

    async init() {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (!accessToken || !refreshToken) {
            this.isAuthenticated.next(false);
            return;
        }
        this.currentAccessToken = accessToken;
        this.currentRefreshToken = refreshToken;
        this.isAuthenticated.next(true);
    }

    login(login: string, password: string): Promise<boolean> {
        return this.http.post(environment.apiConfig.apiUrl + '/token', {
            login: login,
            password: password,
        }, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise()
            .then((response: any) => {
                console.log("response ", response);
                localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken);
                localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
                this.currentAccessToken = response.accessToken;
                this.currentRefreshToken = response.refreshToken;
                this.isAuthenticated.next(true);
                return response;
            }).catch((error: any) => {
                console.log("error", error);
                this.isAuthenticated.next(false);
                throw error;
            });
    }


    logout(): void {
        this.currentRefreshToken = '';
        this.currentAccessToken = '';
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        this.isAuthenticated.next(false);
        this.router.navigate(['/login']);
    }

    storeAccessToken(accessToken: string, refreshToken: string) {
        this.currentAccessToken = accessToken;
        this.currentRefreshToken = refreshToken;
        const storeAccessToken = localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        const storeRefreshToken = localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        return from(Promise.all([storeAccessToken, storeRefreshToken]));
    }

    postTokenRefresh() {
        const refreshToken = of(this.currentRefreshToken);
        return refreshToken.pipe(
            switchMap((token) => {
                if (!token) {
                    return of(null);
                }
                return this.http.post(environment.apiConfig.apiUrl + '/token/refresh', {
                    refreshToken: token
                }, {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json"
                    })
                });
            })
        );
    }

}
