import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_shared/interceptors/jwt.interceptor';
import { AuthGuard } from './_shared/guards/auth.guard';
import { NonAuthGuard } from './_shared/guards/non-auth.guard';
import { CustomTitleStrategy } from './_shared/services/custom-title-strategy.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ReportComponent } from './report/report.component';
import { ReportsComponent } from './reports/reports.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { NavbarComponent } from './_shared/components/navbar/navbar.component';

import { MessageService } from 'primeng/api';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { MenubarModule } from 'primeng/menubar';
import { PasswordModule } from 'primeng/password';
import { ImageModule } from 'primeng/image';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    LogoutComponent,
    ReportsComponent,
    ReportComponent,
    PageNotFoundComponent,

    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,

    LoadingBarRouterModule,

    ButtonModule,
    InputTextModule,
    CardModule,
    MenubarModule,
    PasswordModule,
    ImageModule,
    TableModule,
    ToastModule,
    BlockUIModule,
    ProgressSpinnerModule,
    AvatarModule,
    OverlayPanelModule,
    TieredMenuModule,

    PowerBIEmbedModule,


  ],
  providers: [
    AuthGuard,
    NonAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy
    },
    MessageService,


  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
