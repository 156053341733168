<app-navbar></app-navbar>

<div *ngIf="loadingReport" class="grid grid-nogutter mt-8">
    <div class="col-12 text-center">
        <p-progressSpinner ariaLabel="Loading.." />
        <br>
        Загрузка..
    </div>
</div>

<div class="grid grid-nogutter h-screen">

    <div class="col-12 h-screen">
        <div class="">
            <powerbi-report [hidden]="loadingReport" [cssClassName]="'power-bi-client'" [embedConfig]="embedConfig" [eventHandlers]="eventHandlers">

            </powerbi-report>
        </div>
    </div>

</div>