import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Table } from 'primeng/table';
import { Message, MessageService, SortEvent } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {

    loadingReports: boolean = false;

    reports: any[] = [];

    constructor(
        private http: HttpClient,
        private messageService: MessageService
    ) {

    }

    ngOnInit() {

        this.loadReports();

    }

    loadReports() {
        this.loadingReports = true;
        this.http.get(environment.apiConfig.apiUrl + '/reports').toPromise()
            .then((response: any) => {
                console.log("response ", response);
                this.reports = [];
                response.reports?.forEach((report: any) => {
                    this.reports.push({
                        id: report.id,
                        name: report.name,
                    });
                });
            }).catch((error: any) => {
                console.log("error", error);
                this.messageService.add({
                    severity: 'error',
                    summary: error?.error?.title ?? "Ошибка",
                    detail: error?.error?.detail ?? "Неизвестная ошибка",
                    life: 5000,
                });
            })
            .finally(() => {
                this.loadingReports = false;
            });
    }

}


