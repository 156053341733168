import { Component } from '@angular/core';
import { AuthService } from '../_shared/services/auth.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

    loading: boolean = false;

    constructor(
        private authService: AuthService,
    ) {

    }

    ngOnInit() {
        this.loading = true;
        this.authService.logout();
    }


}