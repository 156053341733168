import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { UserContextService } from '../../services/user-context.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {


    userContextSubscription: Subscription = new Subscription();
    userContext: any = {};

    navbarMenu: MenuItem[] = [];
    profileMenu: MenuItem[] = [];

    constructor(
        private userContextService: UserContextService,
    ) {

    }

    ngOnInit() {
        this.userContextSubscription = this.userContextService.userContext$.subscribe((result: any) => {
            this.userContext = result;
        });

        this.navbarMenu = [
            {
                label: 'Список отчетов',
                icon: 'pi pi-file-o',
                routerLink: '/reports'
            },
        ];
        
        this.profileMenu = [
            {
                label: 'Выход из профиля',
                icon: 'pi pi-sign-out',
                routerLink: '/logout'
            },
        ];
    }

    ngOnDestroy() {
        this.userContextSubscription.unsubscribe();
    }
}