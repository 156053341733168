<app-navbar></app-navbar>

<div class="mt-0">

    <div class="grid grid-nogutter">

        <div class="col-12">

            <!-- <p-card styleClass="m-3"> -->

                <p-table [value]="reports" styleClass="p-datatable-gridlines p-datatable-striped" [paginator]="true"
                    [rows]="50" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
                    [rowsPerPageOptions]="[5, 10, 20, 50, 100]" sortMode="multiple" [loading]="loadingReports"
                    selectionMode="single" [resizableColumns]="true" stateStorage="local" stateKey="tableReports">
                    <ng-template pTemplate="caption">
                        <div class="flex align-items-center justify-content-between">
                            Список доступных отчетов
                            <p-button (onClick)="loadReports()" icon="pi pi-refresh" [text]="true" [plain]="true" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn>
                                <div class="flex flex-wrap align-content-center justify-content-between">
                                    <div class="flex align-items-center justify-content-center">
                                        Наименование
                                    </div>
                                    <div class="flex align-items-center justify-content-center">
                                        <p-columnFilter type="text" field="name" display="menu" />
                                    </div>
                                </div>
                            </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>
                                <span class="p-column-title">Наименование</span>
                                <a [routerLink]="['/report/', item.id]" class="no-underline text-color">
                                    {{ item.name }}
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="1">Нет доступных отчетов, обратитесь на <a href="mailto:help&#64;app-group.ru">help&#64;app-group.ru</a></td>
                        </tr>
                    </ng-template>
                </p-table>

            <!-- </p-card> -->

        </div>

    </div>

</div>