import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class NonAuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isAuthenticated.pipe(
            filter(val => val !== null),
            take(1),
            map(isAuthenticated => {
                if (!isAuthenticated) {
                    return true;
                }
                this.router.navigate(['/']);
                return false;
            })
        );
    }

}
